<template>
    <div class="accountWrap">
        <div class="accountTop">
            <p class="accountTitle">
                安全设置
            </p>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <div class="accountInfo">
            <p>
                <span style="margin-right: 40px">密码修改</span>
            </p>
            <div class="accountBasicWrap">
                <p class="accountInfoList">
                    <span class="accountInfoTitle">手机号码<br>*********68</span>
                    <a-input v-model="form.tel" style="width: 500px" />
                </p>
                <p class="accountInfoList">
                    <span class="accountInfoTitle">短信验证码</span>
                    <a-input v-model="form.num" style="width: 500px" />
                    <span class="sendNum">发送验证码</span>
                </p>
                <p class="accountInfoList">
                    <span class="accountInfoTitle">新密码</span>
                    <a-input v-model="form.psd" style="width: 500px" />
                </p>
                <p class="accountInfoList">
                    <span class="accountInfoTitle">确认密码</span>
                    <a-input v-model="form.psd1" style="width: 500px" />
                </p>
            </div>
            <p style="text-align: center; margin-top: 30px">
                <a-button style="margin-right: 30px" @click="form = {}">
                    重置
                </a-button>
                <a-button type="primary" @click="onSure">
                    确认
                </a-button>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
    data() {
        return {
            // 基础信息
            form: {
                num: '',
                tel: '',
                psd: '',
                psd1: ''
            }
        }
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 确认修改
        onSure() {

        }
    }
}
</script>

<style lang="scss" scoped>
.accountWrap {
    margin: 30px 80px;
    color: #333;
    .accountTop {
        display: flex;
        justify-content: space-between;
    }
    .accountTitle {
        font-size: 20px;
        font-weight: bold;
    }
    .accountInfo {
        margin-bottom: 20px;
        .accountBasicWrap {
            .accountInfoList {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .accountInfoTitle {
                    display: inline-block;
                    width: 100px;
                    margin-right: 30px;
                    text-align: right;
                }
                .sendNum {
                    margin-left: 30px;
                    color: #1890ff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
